exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-rsvp-jsx": () => import("./../../../src/pages/rsvp.jsx" /* webpackChunkName: "component---src-pages-rsvp-jsx" */),
  "component---src-pages-upasana-js": () => import("./../../../src/pages/upasana.js" /* webpackChunkName: "component---src-pages-upasana-js" */),
  "component---src-templates-events-images-js": () => import("./../../../src/templates/events-images.js" /* webpackChunkName: "component---src-templates-events-images-js" */)
}

